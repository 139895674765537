import moment from 'moment';

export function convertDateToDateAnd12HourFormat(date: Date | string) {
  return moment(date).format(`YYYY-MM-DD hh:mm a`);
}

export function convertDateToYear(date: Date) {
  return moment(date).format(`YYYY`);
}

export function convertDateToDDMMYY(date: Date) {
  return moment(date).format(`DD/MM/YY`);
}

export function getDifferenceInMinutes(
  startDate: Date | string | null,
  endDate: Date | string | null
) {
  if (!startDate || !endDate) {
    return 0;
  }
  return moment(endDate).diff(startDate, `minute`);
}

export function addMinutesToCurrentDate(currentDate: Date | null, minutes: number) {
  if (!currentDate) {
    return null;
  }

  return moment(currentDate)
    .add(minutes, `minutes`)
    .toDate();
}

export function convertDateToOrderFormat(currentDate: Date | string) {
  return moment(currentDate).format(`MMM DD, YYYY hh:mm:a`);
}

export function formatDateToMMMDDYYYY(date: Date | string) {
  if (!date) {
    return moment(new Date()).format(`MMM DD YYYY`);
  }

  return moment(date).format(`MMM DD YYYY`);
}

export function getOneWeekFromDate(date: Date) {
  if (!date) {
    return new Date();
  }
  return moment(date)
    .add(1, `week`)
    .toDate();
}

export function formatDateToMMMDDYYYYWithComma(date: Date | string) {
  if (!date) {
    return moment(new Date()).format(`MMM DD, YYYY`);
  }

  return moment(date).format(`MMM DD, YYYY`);
}

export function getCurrentMonth() {
  return moment(new Date())
    .local()
    .format(`MMMM`);
}

export function getNumberOfDaysInCurrentMonth() {
  return moment(new Date())
    .local()
    .daysInMonth();
}

export function getTodayDateStartTime() {
  return moment(new Date())
    .local()
    .startOf(`day`)
    .toDate();
}
