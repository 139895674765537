
















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
@Component({})
export default class ModalFormLayout extends Vue {
  @Prop({})
  title!: string;

  @Emit(`close`)
  closeForm() {
    return false;
  }
}
