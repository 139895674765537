























































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { QuestionBankListItem } from '@/models/management';
import { convertDateToDateAnd12HourFormat } from '@/utils/moment';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
@Component({
  components: { EmptyPlaceholder },
})
export default class QuestionBankManagementList extends Vue {
  @Prop({ required: true })
  data!: QuestionBankListItem;

  @Prop({ default: false })
  isModerationMode!: boolean;

  @Prop({ required: true })
  previousCheckboxes!: number[];

  @Prop({ default: false })
  showEmptyPlaceholder!: boolean;

  currentCheckboxes: number[] = [];

  get showPlaceholder() {
    return this.showEmptyPlaceholder;
  }

  mounted() {
    this.currentCheckboxes = this.previousCheckboxes;
  }

  @Watch(`previousCheckboxes`)
  updateCurrentCheckboxes() {
    this.currentCheckboxes = this.previousCheckboxes;
    this.returnCheckboxes();
  }

  formatDate(data: Date) {
    return convertDateToDateAnd12HourFormat(data);
  }

  @Emit(`delete`)
  deleteItem(item: QuestionBankListItem) {
    return item;
  }

  @Emit(`reject`)
  rejectItem(item: QuestionBankListItem) {
    return item;
  }

  @Emit(`approve`)
  approveItem(item: QuestionBankListItem) {
    return item;
  }

  @Emit(`editQuestion`)
  redirectToEditQuestions(id: number) {
    return id;
  }

  updateCheckbox(value: number) {
    if (this.currentCheckboxes.includes(value)) {
      this.currentCheckboxes = this.currentCheckboxes.filter((item) => {
        return item !== value;
      });
    } else {
      this.currentCheckboxes.push(value);
    }
    this.returnCheckboxes();
  }

  @Emit(`checkboxUpdate`)
  returnCheckboxes() {
    return this.currentCheckboxes;
  }
}
