




































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import ManagementLayout from '@/layouts/management/ManagementLayout.vue';
import SearchBar from '@/components/common/SearchBar.vue';
import ManagementList from '@/components/management/ManagementList.vue';
import { FolderItem } from '@/models/miscellaneous';
import { CourseListItem, QuestionBankListItem } from '@/models/management';
import { CourseStores } from '@/store/course';
import moment from 'moment';
import HomeworkTable from '@/components/homework/HomeworkTable.vue';
import { HomeworkListItem, RetrieveHomeworkParams } from '@/models/homework';
import { QuestionStores } from '@/store/questions';
import QuestionBankManagementList from '@/components/management/QuestionBankManagementList.vue';
import ModalFormLayout from '@/layouts/common/ModalFormLayout.vue';
import { HomeworkStores } from '@/store/homeworks';
import { PaginationDetails } from '@/models/generic';
import { FetchCoursesWithParams } from '@/models/courses';
import { FetchHomeworkQuestionsParams } from '@/models/questions';
@Component({
  components: {
    Page,
    SearchBar,
    ManagementLayout,
    ManagementList,
    HomeworkTable,
    QuestionBankManagementList,
    ModalFormLayout,
  },
})
export default class Homework extends Vue {
  // Data / Variables
  currentFolders: FolderItem[] = [];
  currentCourses: CourseListItem[] = [];
  currentHomeworks: HomeworkListItem[] = [];
  currentQuestions: QuestionBankListItem[] = [];
  // UI controls
  isDeleteButtonDisabled = true;
  // Modal
  isAddQuestionModalShown = false;
  selectedQuestionType = '';
  // Stores
  courseListStore = CourseStores.list;
  homeworkListStore = HomeworkStores.list;
  homeworkStateStore = HomeworkStores.state;
  homeworkDetailStore = HomeworkStores.detail;
  questionListStore = QuestionStores.list;
  questionDetailStore = QuestionStores.detail;
  // Pagination Details
  currentKeyword = ``;
  currentPage = 1;
  currentLimit = 10;
  currentTotalCount = 0;
  isPaginationReset = false;
  isPageSpecified = false;
  //Checkboxes
  questionCheckboxes: number[] = [];
  get folders() {
    return this.currentFolders;
  }

  get courses() {
    return this.currentCourses;
  }

  get homeworks() {
    return this.currentHomeworks;
  }

  get questions() {
    return this.currentQuestions;
  }

  get modalTitle() {
    return `Choose a question type to add`;
  }

  get questionTypeData() {
    return [
      {
        type: `Mcq`,
        name: `MCQ`,
      },
      {
        type: `Essay`,
        name: `Subjective`,
      },
    ];
  }

  mounted() {
    if (
      this.homeworkStateStore.homeworkFolderState.length > 0 &&
      this.homeworkStateStore.isModified
    ) {
      // Refetch state from API depending on the previous folder state
      this.currentFolders = this.homeworkStateStore.homeworkFolderState;
      switch (this.currentFolders.length) {
        case 2: {
          // Containing Courses and Course Name
          this.currentCourses = this.homeworkStateStore.courseData;
          this.currentPage = this.homeworkStateStore.currentPageState[this.currentFolders.length];
          this.currentLimit = this.homeworkStateStore.currentLimitState[this.currentFolders.length];
          this.currentTotalCount = this.homeworkStateStore.currentTotalCountState[
            this.currentFolders.length
          ];
          this.currentKeyword = this.homeworkStateStore.currentKeywordState[
            this.currentFolders.length
          ];
          this.isPageSpecified = true;
          break;
        }
        case 3: {
          // Containing Courses and Course Name
          this.currentCourses = this.homeworkStateStore.courseData;
          this.currentHomeworks = this.homeworkStateStore.homeworkData;
          this.currentPage = this.homeworkStateStore.currentPageState[this.currentFolders.length];
          this.currentLimit = this.homeworkStateStore.currentLimitState[this.currentFolders.length];
          this.currentTotalCount = this.homeworkStateStore.currentTotalCountState[
            this.currentFolders.length
          ];
          this.currentKeyword = this.homeworkStateStore.currentKeywordState[
            this.currentFolders.length
          ];
          this.isPageSpecified = true;
          break;
        }
        default: {
          break;
        }
      }
      this.retrieveData();
    } else if (
      this.homeworkStateStore.homeworkFolderState.length > 0 &&
      !this.homeworkStateStore.isModified
    ) {
      // Set back previous States
      this.currentFolders = this.homeworkStateStore.homeworkFolderState;
      this.currentCourses = this.homeworkStateStore.courseData;
      this.currentHomeworks = this.homeworkStateStore.homeworkData;
      this.currentQuestions = this.homeworkStateStore.questionData;
      this.currentPage = this.homeworkStateStore.currentPageState[this.currentFolders.length];
      this.currentLimit = this.homeworkStateStore.currentLimitState[this.currentFolders.length];
      this.currentTotalCount = this.homeworkStateStore.currentTotalCountState[
        this.currentFolders.length
      ];
      this.currentKeyword = this.homeworkStateStore.currentKeywordState[this.currentFolders.length];
      this.isPageSpecified = true;
    } else {
      // Base State
      this.currentFolders.push({ id: -1, name: `Courses` });
      this.retrieveData();
    }
  }

  beforeDestroy() {
    this.saveCurrentState();
  }

  // Search
  searchItems(payload: string) {
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.currentKeyword = payload;
    this.retrieveData();
  }

  //Pagination
  updatePagination(payload: PaginationDetails) {
    this.currentPage = payload.page;
    this.currentLimit = payload.limit;
    this.retrieveData();
  }

  resetPaginationStatus(payload: boolean) {
    this.isPaginationReset = payload;
  }

  resetCurrentPageStatus(payload: boolean) {
    this.isPageSpecified = payload;
  }

  resetFolders(payload: FolderItem[]) {
    this.currentFolders = payload;
    this.currentPage = this.homeworkStateStore.currentPageState[this.currentFolders.length];
    this.currentLimit = this.homeworkStateStore.currentLimitState[this.currentFolders.length];
    this.currentKeyword = this.homeworkStateStore.currentKeywordState[this.currentFolders.length];
    this.isPageSpecified = true;
    this.saveCurrentState();
    this.retrieveData();
  }

  // Retrieve Data
  retrieveData() {
    switch (this.currentFolders.length) {
      case 1: {
        const payload: Partial<FetchCoursesWithParams> = {
          page: this.currentPage,
          limit: this.currentLimit,
        };
        if (this.currentKeyword.length > 0) {
          payload.keyword = this.currentKeyword;
        }
        this.courseListStore.retrieveCoursesWithParams(payload);
        break;
      }
      case 2: {
        const payload: Partial<RetrieveHomeworkParams> = {
          page: this.currentPage,
          limit: this.currentLimit,
          courseid: this.currentFolders[this.currentFolders.length - 1].id,
        };
        if (this.currentKeyword.length > 0) {
          payload.keyword = this.currentKeyword;
        }
        this.homeworkListStore.retrieveHomeworks(payload);
        break;
      }
      case 3: {
        const payload: Partial<FetchHomeworkQuestionsParams> = {
          page: this.currentPage,
          limit: this.currentLimit,
          homework_id: this.currentFolders[this.currentFolders.length - 1].id,
        };
        if (this.currentKeyword.length > 0) {
          payload.keyword = this.currentKeyword;
        }
        this.questionListStore.retrieveHomeworkQuestionsWithParams(payload);
        break;
      }
      default: {
        break;
      }
    }
  }

  // Folder Functions
  addFolderRouteForCourses(data: CourseListItem) {
    this.saveCurrentState();
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.currentKeyword = ``;
    this.currentFolders.push({
      id: data.id,
      name: data.name,
    });
    this.retrieveData();
  }

  addFolderRouteForHomeworks(data: HomeworkListItem) {
    this.saveCurrentState();
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.currentKeyword = ``;
    this.currentFolders.push({
      id: data.id,
      name: data.name,
    });
    // Placeholder: Replace this : TODO
    this.retrieveData();
  }

  // Watch / Parse Function
  @Watch(`courseListStore.response`)
  parseCourseData() {
    if (this.courseListStore.response) {
      this.currentCourses = [];
      this.currentTotalCount = this.courseListStore.response.totalCount;
      this.courseListStore.response.objects.forEach((data) => {
        this.currentCourses.push({
          id: data.id,
          courseId: data.courseId,
          name: `${data.courseName} - ${data.form.name} - ${moment(data.courseStartTime).format(
            `YYYY`
          )} - ${data.teacher.firstname} ${data.teacher.lastname}`,
          numberOfCourses: -1,
        });
      });
      this.homeworkStateStore.updateHomeworkCoursesState(this.currentCourses);
    }
  }

  @Watch(`homeworkListStore.response`)
  parseHomeworkData() {
    if (this.homeworkListStore.response) {
      this.currentHomeworks = [];
      this.currentTotalCount = this.homeworkListStore.totalSize;
      this.homeworkListStore.response.objects.forEach((data) => {
        this.currentHomeworks.push({
          ...data,
          startDate: data.startDate,
          endDate: data.deadline,
        });
      });
      this.homeworkStateStore.updateHomeworksState(this.currentHomeworks);
    }
  }

  @Watch(`homeworkDetailStore.response`)
  refetchHomeworkData() {
    if (this.homeworkDetailStore.response) {
      this.retrieveData();
    }
  }

  @Watch(`questionListStore.response`)
  parseQuestionData() {
    if (this.questionListStore.response) {
      this.currentQuestions = [];
      this.currentTotalCount = this.questionListStore.totalSize;
      this.questionListStore.response.objects.forEach((data) => {
        this.currentQuestions.push({
          id: data.id,
          publicId: data.quesId,
          name: data.quesName,
          createdBy: data.createdBy,
          modifiedBy: data.updatedBy,
          createdDate: moment(data.createdAt).toDate(),
          modifiedDate: moment(data.updatedAt).toDate(),
        });
      });
      this.homeworkStateStore.updateHomeworkQuestionsState(this.currentQuestions);
    }
  }

  @Watch(`questionDetailStore.response`)
  updateCurrentStateAfterDelete() {
    if (this.questionDetailStore.response) {
      this.retrieveData();
    }
  }

  // Redirect Functions
  redirectToCreateHomework() {
    this.saveCurrentState();
    this.$router.push({
      path: '/homeworks/create',
    });
  }

  redirectToEditHomework(data: HomeworkListItem) {
    this.saveCurrentState();
    this.$router.push({
      path: `/homeworks/${data.id}/edit`,
    });
  }

  deleteHomework(data: HomeworkListItem) {
    this.homeworkDetailStore.deleteHomeworkById(data.id);
  }

  redirectToEditQuestions(id: number) {
    this.saveCurrentState();
    this.$router.push({
      path: `/homeworks/questions/${id}/edit`,
    });
  }

  redirectToCreateQuestion() {
    // Find
    const index = this.questionTypeData.findIndex((data) => {
      return data.name === this.selectedQuestionType;
    });

    this.saveCurrentState();

    this.$router.push({
      path: `/homeworks/questions/create`,
      query: {
        type: this.questionTypeData[index].type,
      },
    });
  }

  // Delete Functions
  deleteSelectedQuestion(payload: QuestionBankListItem) {
    this.questionDetailStore.deleteHomeworkQuestionById(payload.id);
  }

  deleteSelectedQuestions(payload: number[]) {
    this.questionCheckboxes = payload;
    this.isDeleteButtonDisabled = this.questionCheckboxes.length > 0 ? false : true;
  }

  // State
  saveCurrentState() {
    this.homeworkStateStore.saveHomeworkFolderState(this.currentFolders);
    this.homeworkStateStore.saveHomeworkCoursesState(this.currentCourses);
    this.homeworkStateStore.saveHomeworksState(this.currentHomeworks);
    this.homeworkStateStore.saveHomeworkQuestionsState(this.currentQuestions);
    this.homeworkStateStore.saveHomeworkCurrentPage({
      folderLevel: this.currentFolders.length,
      value: this.currentPage,
    });
    this.homeworkStateStore.saveHomeworkCurrentLimit({
      folderLevel: this.currentFolders.length,
      value: this.currentLimit,
    });
    this.homeworkStateStore.saveHomeworkCurrentTotalCount({
      folderLevel: this.currentFolders.length,
      value: this.currentTotalCount,
    });
    this.homeworkStateStore.saveHomeworkCurrentKeyword({
      folderLevel: this.currentFolders.length,
      value: this.currentKeyword,
    });
  }
}
