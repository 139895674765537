




































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
import { HomeworkListItem } from '@/models/homework';
@Component({
  components: { EmptyPlaceholder },
})
export default class HomeworkTable extends Vue {
  @Prop({ required: true })
  data!: HomeworkListItem[];

  @Prop({ default: false })
  showEmptyPlaceholder!: boolean;

  get showPlaceholder() {
    return this.showEmptyPlaceholder;
  }

  @Emit(`route`)
  navigateToQuestions(data: HomeworkListItem) {
    return data;
  }

  @Emit(`edit`)
  redirectToEditHomework(data: HomeworkListItem) {
    return data;
  }

  @Emit(`delete`)
  deleteHomework(data: HomeworkListItem) {
    return data;
  }
}
